import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ theme }) => theme.size(2)};
  padding-right: ${({ theme }) => theme.size(2)};

  @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding-left: ${({ theme }) => theme.size(4)};
    padding-right: ${({ theme }) => theme.size(4)};
  }
`

export default Wrapper
