import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  body {
    color: ${({ theme }) => theme.color.gray};
    font-family: ${({ theme }) => theme.font.family.main};
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;

    @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
      text-align: initial;
    }
  }

  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${({ theme }) => theme.size(4)};
    
    @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
      margin-bottom: 0;
    }

    a & {
      margin-top: ${({ theme }) => theme.size(4)};
      
      @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
        margin-top: ${({ theme }) => theme.size(6)};
      }
    }
  }

  h1, 
  h2 {
    color: ${({ theme }) => theme.color.orange};
    text-shadow: 2px 4px ${({ theme }) => theme.color.red};
    font-family: ${({ theme }) => theme.font.family.heading};
    font-size: ${({ theme }) => theme.font.size.mobile.xl};
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: ${({ theme }) => theme.size(3)};

    @media(min-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: ${({ theme }) => theme.font.size.desktop.xl};
      text-shadow: 1px 2px ${({ theme }) => theme.color.red};
      margin-bottom: ${({ theme }) => theme.size(6)};
    }
  }

  p {
    font-size: ${({ theme }) => theme.font.size.mobile.m};
    margin-bottom: ${({ theme }) => theme.size(4)};

    @media(min-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: ${({ theme }) => theme.font.size.desktop.m};
      margin-bottom: ${({ theme }) => theme.size(6)};
    }
  }

  em {
    display: inline-block;
    color: ${({ theme }) => theme.color.black};
    font-family: ${({ theme }) => theme.font.family.emphasis};
    font-size: ${({ theme }) => theme.font.size.mobile.l};
    font-style: normal;

    @media(min-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: ${({ theme }) => theme.font.size.desktop.l};
    }
  }

  label {
    display: block;
    font-size: ${({ theme }) => theme.font.size.mobile.s};
    text-align: left;

    @media(min-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: ${({ theme }) => theme.font.size.desktop.s};
    }

    @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
      text-align: left;
    }
  }

  input {
    width: 100%;
    color: ${({ theme }) => theme.color.black};
    font-family: ${({ theme }) => theme.font.family.main};
    font-size: ${({ theme }) => theme.font.size.mobile.s};
    padding: ${({ theme }) => theme.size(1)} 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray};
    outline: 0;

    &:focus {
      border-bottom-color: ${({ theme }) => theme.color.orange};
    }
  }
`

export default GlobalStyles
