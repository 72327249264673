import Wrapper from './styled'

const Container = ({ children, ...rest }) => {
  return(
    <Wrapper className='container' {...rest}>
      {children}
    </Wrapper>
  )
}

export default Container
