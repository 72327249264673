import styled from 'styled-components'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;

  @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
    grid-template-columns: repeat(2, 50%);
  }

  // Only applied on the first row (cover) because image here is bigger

  &.cover {
    @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
      grid-template-columns: 60% 40%;
    }

    @media(min-width: ${({ theme }) => theme.breakpoint.xl}) {
      grid-template-columns: repeat(2, 50%);
    }
  }

  .cover {
    @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
      &__image {
        img {
          position: absolute;
          max-width: inherit;
          right: ${({ theme }) => theme.size(2)};
        }
      }

      &__content {
        padding-top: ${({ theme }) => theme.size(7)};
        padding-bottom: ${({ theme }) => theme.size(7)};
      }
    }
  }
`

export default Wrapper
