import { useState } from 'react'

import Toast from '../Toast'
import { Wrapper, Label, Input, TextArea } from './styled'
import { db } from '../../config/firebase'

const Form = () => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [loading, setLoader] = useState(false)
  const [toast, setToast] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    setLoader(true)

    db.collection('ContactForm').add({
      to: `Bravas <contact@bravas.app>`,
      from: `${name} <${email}>`,
      message: {
        subject: `HelpForm - ${name}`,
        text: message
      }
    })
      .then(() => {
        setName('')
        setEmail('')
        setMessage('')
        setLoader(false)
        setToast('success')
      })
      .catch(err => {
        console.error(err.message)
        setLoader(false)
        setToast('error')
      })

    const timer = setTimeout(setToast(''), 50)
    return () => clearTimeout(timer)
  }

  return (
    <Wrapper className='form'>
      <form onSubmit={handleSubmit}>
        <div className='form__group'>
          <Input
            id='name'
            type='text'
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
          <Label htmlFor='name' className={name && 'has-value'}>Name</Label>
        </div>
        <div className='form__group'>
          <Input
            id='email'
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
          <Label htmlFor='email' className={email && 'has-value'}>Email</Label>
        </div>
        <div className='form__group'>
          <TextArea
            id='message'
            value={message}
            onChange={e => setMessage(e.target.value)}
            required
          />
          <Label htmlFor='message' className={message && 'has-value'}>Message</Label>
        </div>
        <button type='submit' disabled={loading}>Send</button>
      </form>
      {toast === 'success' && <Toast variation='success' open>Message has been submitted</Toast>}
      {toast === 'error' && <Toast variation='error' open>There was an error. Please try again</Toast>}
    </Wrapper>
  )
}

export default Form
