import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAoLLZuBjasdaujcS8oKITiAumBJnQUcXE",
  authDomain: "bravas-b4fc7.firebaseapp.com",
  databaseURL: "https://bravas-b4fc7.firebaseio.com",
  projectId: "bravas-b4fc7",
  storageBucket: "bravas-b4fc7.appspot.com",
  messagingSenderId: "897862626630",
  appId: "1:897862626630:web:b08c71baaf40bbaab78947",
  measurementId: "G-0RL8MR6519"
})

export const db = firebaseApp.firestore();
