import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.size(3)};
  right: ${({ theme }) => theme.size(3)};
  padding: ${({ theme }) => theme.size(2)};
  border-radius: 4px;
  max-width: 375px;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 999;
  
  &.toast {
    &--success {
      color: hsl(117deg 76% 38%);
      background-color: hsl(117deg 94% 94%);
    }
    &--error {
      color: hsl(0deg 98% 50%);
      background-color: hsl(0deg 100% 95%);
    }
  }
`;

export default Wrapper;
