import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.size(8)};

  @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding-right: ${({ theme }) => theme.size(6)};
    margin-bottom: 0;
  }

  .form {
    &__group{
      margin-bottom: ${({ theme }) => theme.size(3)};
    }
  }

  form {
    & > .form__group:first-child {
      margin-top: ${({ theme }) => theme.size(3)};
    }

    & > .form__group:last-of-type {
      margin-bottom: ${({ theme }) => theme.size(6)};
    }
  }

  button {
    cursor: pointer;
    display: block;
    width: 100%;
    font-size: ${({ theme }) => theme.font.size.desktop.m};
    font-family: ${({ theme }) => theme.font.family.heading};
    padding: ${({ theme }) => theme.size(2.5)};
    border: 0;
    border-radius: ${({ theme }) => theme.size(1.5)};
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.orange};
    transition: background-color .35s ease;

    &:hover {
      background-color: hsl(22, 100%, 53%);
    }

    &:focus {
      outline: 0;
    }

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
`

export const Input = styled.input``

export const TextArea = styled.input``

export const Label = styled.label`
  transform: translateY(-33px);
  transition: transform .35s ease, color .35s ease;

  &.has-value,
  ${Input}:focus + & {
    color: ${({ theme }) => theme.color.orange};
    transform: translateY(-66px);
  }
`