import Row from './components/Row'
import Container from './components/Container'
import Grid from './components/Grid/'
import Box from './components/Box'
import Form from './components/Form'

import APPLE_STORE_BADGE from './assets/apple-store-badge.png'
import ILLUSTRATION_1 from './assets/illustration-1.png'
import ILLUSTRATION_2 from './assets/illustration-2.png'
import ILLUSTRATION_3 from './assets/illustration-3.png'
import ILLUSTRATION_4 from './assets/illustration-4.png'

function Page() {

  const APPSTORE_URL = 'https://apps.apple.com/us/app/bravas/id1559370577';

  return (
    <main className='page'>
      <Row>
        <Container>
          <Grid className='cover'>
            <Box className='cover__image'>
              <img src={ILLUSTRATION_1} alt='Mobile phones showing Bravas app' />
            </Box>
            <Box className='cover__content'>
              <h1>
                Find the <br />
                best bravas <br /> 
                near you!
              </h1>
              <p>Check the distance from where you are, availability, prices and evaluations.</p>
              <em>Open, choose and go!</em>
              <figure>
                <a href={APPSTORE_URL} target="_blank" rel='noreferrer'>
                  <img src={APPLE_STORE_BADGE} alt="Apple Store badge" />
                </a>
              </figure>
            </Box>
          </Grid>
        </Container>
      </Row>
      <Row className='row--yellow'>
        <Container>
          <Grid>
            <Box>
              <h2>First and only!</h2>
              <p>Bravas is the first app dedicated to help you to find places for enjoy one of the most popular tapas from Spain!</p>
              <figure>
                <a href={APPSTORE_URL} target="_blank" rel='noreferrer'>
                  <img src={APPLE_STORE_BADGE} alt="Apple Store badge" />
                </a>
              </figure>
            </Box>
            <Box>
              <img src={ILLUSTRATION_2} alt='Mobile phones showing Bravas app' />
            </Box>
          </Grid>
        </Container>
      </Row>
      <Row className='row--orange'>
        <Container>
          <Grid>
            <Box>
              <img src={ILLUSTRATION_3} alt='Mobile phones showing Bravas app' />
            </Box>
            <Box>
              <h2>Open, choose and go!</h2>
              <p>Bravas is super easy to use. Just open the app, choose the placet based on the distance, availability, prices or evaluations and go!</p>
              <figure>
                <a href={APPSTORE_URL} target="_blank" rel='noreferrer'>
                  <img src={APPLE_STORE_BADGE} alt="Apple Store badge" />
                </a>
              </figure>
            </Box>
          </Grid>
        </Container>
      </Row>
      <Row className='row--white'>
        <Container>
          <Grid>
            <Box>
              <h2>Get in touch</h2>
              <p>
                Doubts, feedback, suggestions? <br />
                Write us!
              </p>
              <Form />
            </Box>
            <Box>
              <img src={ILLUSTRATION_4} alt='Mobile phones showing Bravas app' />
            </Box>
          </Grid>
        </Container>
      </Row>
    </main>
  );
}

export default Page