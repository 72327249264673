import COLORS from './colors'

const theme = {
  color: {
    white: COLORS.white,
    gray: COLORS.gray,
    black: COLORS.black,
    yellow: COLORS.yellow,
    orange: COLORS.orange,
    red: COLORS.red,
  },
  font: {
    family: {
      main: 'Open Sans, Helvetica, Verdana, sans-serif',
      heading: 'Helsinki, Helvetica, Verdana, sans-serif',
      emphasis: 'Greatquest, cursive',
    },
    size: {
      desktop: {
        s: '1.125rem',
        m: '1.75rem',
        l: '2.5rem',
        xl: '3.75rem',
      },
      mobile: {
        s: '1rem',
        m: '1.5rem',
        l: '2.25rem',
        xl: '3.5rem',
      },
    },
    lineheight: {
      base: '1.4',
      double: '2',
      none: '1',
    },
  },
  breakpoint: {
    xs: '320px',
    sm: '480px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  size(size) {
    return `${size*8}px`
  },
}

export default theme
