import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.size(7)};
  padding-bottom: ${({ theme }) => theme.size(7)};

  &.row {
    &--yellow {
      background-color: ${({ theme }) => theme.color.yellow};

      h2 {
        color: ${({ theme }) => theme.color.white};
        text-shadow: 1px 2px ${({ theme }) => theme.color.black};
      }

      p {
        color: ${({ theme }) => theme.color.white};
        margin-bottom: 0;
      }
    }

    &--orange {
      background-color: ${({ theme }) => theme.color.orange};

      h2 {
        color: ${({ theme }) => theme.color.yellow};
        text-shadow: 1px 2px ${({ theme }) => theme.color.red};
      }

      p {
        color: ${({ theme }) => theme.color.white};
        margin-bottom: 0;
      }
    }
  }
`

export default Wrapper
