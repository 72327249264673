import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  padding-left: ${({ theme }) => theme.size(2)};
  padding-right: ${({ theme }) => theme.size(2)};

  @media(min-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`

export default Wrapper
