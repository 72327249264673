import Wrapper from './styled'

const Box = ({ 
  padding,
  children, 
  ...rest }) => {
  return(
    <Wrapper className='box' padding={padding} {...rest}>
      {children}
    </Wrapper>
  )
}

export default Box
