import Wrapper from './styled'

const Grid = ({ children, ...rest }) => {
  return(
    <Wrapper className='grid' {...rest}>
      {children}
    </Wrapper>
  )
}

export default Grid
