import Wrapper from './styled'

const Row = ({ children, ...rest }) => {
  return(
    <Wrapper className='row' {...rest}>
      {children}
    </Wrapper>
  )
}

export default Row
