import { useState, useEffect } from 'react'
import Wrapper from './styled';

const Toast = ({ open, duration = 3000, variation, children, ...rest }) => {

  const [isOpen, setIsOpen] = useState(open);
  const handleClose = () => setIsOpen(false);

  // Hook to handle timer to close Toast
  useEffect(() => {
    if (duration) {
      const timer = setTimeout(handleClose, duration)
      return () => clearTimeout(timer)
    }
  }, [])

  return (
    <>
      {isOpen && (
        <Wrapper className={`toast toast--${variation}`} {...rest}>
          <div className='toast__wrapper'>
            <div className="Toast__content">
              {children}
            </div>
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default Toast
